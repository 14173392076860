import { useEffect, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core"
import { DefaultTableModel, ITableModel } from "../../models/orders/table.model";
import { VendorTable } from "../../components/vendor-table.component";
import { getOrgProfiles, getSearcherCompany, getStateProducts, getStates, getVendorAssignment } from "../../services/order.service";
import Loading from "../../components/loading.component";

const VendorsPage = () => {
    const [tableState, setTableState] = useState<ITableModel>(DefaultTableModel);
    const [vendors, setVendors] = useState<any>([]);
    const [states, setStates] = useState<any>([]);
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [loadingVendors, setLoadingVendors] = useState(false);
    const [loadingStates, setLoadingStates] = useState(false); 
    const pagination = true;

    const updatePageState = (prop:string, value:any) => {
        setTableState(old => ({...old, [prop]: value}))
    }

    useEffect(() => {
        setLoadingVendors(true);
        setLoadingStates(true);
        getOrgProfiles().then(results=>{
          if(results && results.success && results.success.msg){
            var vendors = results.success.msg;
            setVendors(vendors);
          }
        }).finally(() => setLoadingVendors(false));
        getStates().then(results => {
            if(results) {
                setStates(results);
            } 
        }).finally(() => setLoadingStates(false)) 
      }, [])

    useEffect(() => {
        if(vendors && vendors.length != 0 && states && states.length != 0) {
            getVendorAssignment().then(results => {
                if(results && results.success && results.success.msg && results.success.msg.length > 0) {
                    var rows = results.success.msg.map((item: any, index: any) => ({
                        ...item,
                        id: index + 1,
                        update: true
                    }))
                    setTableState(old => ({
                        ...old,
                        rows: rows,
                        pagination: pagination,
                        hiddenColumns: [],
                        settings: {...old.settings, 
                        updatePageState: updatePageState}
                    }))
                    
                }
                else {
                    setTableState(old => (
                        {
                          ...old,
                          pagination: pagination,
                          hiddenColumns: [],
                          settings: {...old.settings, 
                            updatePageState: updatePageState}
                        }
                      ))
                }
            }).finally(() => setShowSplashScreen(false));
        }
    }, [vendors, states])

    return (
        <>
            <PageTitle breadcrumbs={[]} description='#XRS-45670'>
                Vendors
            </PageTitle>
            {showSplashScreen || loadingVendors || loadingStates ? <Loading/> : 
                <div className='card bg-white min-h-750px' style={{border: 'solid 1px #E8E8E8'}}>
                    <div className='card-header pt-3 pb-2 d-flex align-items-center'>
                        <h3 className='card-title align-items-start flex-row'>
                            <span className='card-label fw-light fs-3 text-primary'>Vendors</span>
                        </h3>
                    </div>

                    <div className='g-5 gx-xxl-8 border-gray rounded dashboard-table'>
                        <VendorTable 
                            tableState={tableState}
                            vendors={vendors}
                            states={states}
                            className='bg-white new-order-card' />
                    </div>
                </div>
            }
        </>
    )
}

const VendorsWrapper = () => {
    return (
        <>
        <PageTitle breadcrumbs={[]}>VENDOR ASSIGNMENT</PageTitle>
        <VendorsPage />
        </>
    )
}

export {VendorsWrapper}