import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { Logout } from '../components/auth/logout.component'
import { FaqsWrapper } from '../pages/faqs/FaqsWrapper'
import { useAuth } from '../contexts/auth.context'
import { ContactWrapper } from '../pages/contact/ContactWrapper'
import { ResourcesWrapper } from '../pages/resources/ResourcesWrapper'
import { VendorsWrapper } from '../pages/vendors/VendorsWrapper'

const PrivateRoutes = () => {
  const OrderPage = lazy(() => import('./order.routes'))
  const {currentUser} = useAuth();
  let windowObj = window as any;
  let pendo = windowObj.pendo;

  useEffect(()=>{
    if(currentUser && currentUser.user_name) {
    pendo.initialize({
      visitor: {
          id:              currentUser?.user_email,  // Required if user is logged in
          email:        currentUser?.user_email,// Recommended if using Pendo Feedback, or NPS Email
          full_name:    currentUser?.user_name// Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
      },

      account: {
          id:           'Client' // Required if using Pendo Feedback
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
      }
    });
    }

  },[currentUser])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        <Route path='logout/*' element={<Logout />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />

        <Route path='resources' element={<ResourcesWrapper/>} />

        <Route path='vendors' element={<VendorsWrapper />} />

        <Route path='builder' element={<BuilderPageWrapper />} />

        <Route path='contact' element={<ContactWrapper />} />

        <Route path='faqs' element={<FaqsWrapper />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
